
import { defineComponent, reactive, ref } from 'vue';
import { useInject } from '@/utils/inject';
import SearchTable from '@/components/table/SearchTable.vue';
import { DataItem } from '@/types/app.global';
import TableModel from '@/components/composite/TableModel.vue';

/**
 * 结算价变更记录
 */
export default defineComponent({
  name: 'ProfitApply',
  components: { TableModel, SearchTable },
  setup() {
    const { https, mapper } = useInject();
    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'operator',
              label: '申请人',
              type: 'agent'
            },
            {
              field: 'receiver',
              label: '接收人',
              type: 'agent'
            }
          ]
        },
        remote: {
          action: https?.market.listProfitModify
        }
      },
      cols: [
        {
          field: 'id',
          label: 'ID'
        },
        {
          field: 'oprAlias',
          label: '申请人'
        },
        {
          field: 'oprPhone',
          label: '申请人手机号'
        },
        {
          field: 'recAlias',
          label: '接收人'
        },
        {
          field: 'recPhone',
          label: '接收人手机号'
        },
        {
          field: 'backerNo',
          label: '渠道',
          options: mapper?.device.backerNo
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.market.profitModifyStatus
        },
        {
          field: 'remark',
          label: '备注'
        },
        {
          field: 'happenTime',
          label: '申请时间'
        },
        {
          field: 'dealTime',
          label: '处理时间'
        }
      ],
      commands: [] as any[]
    };

    const detailRef = ref();
    const detailOpts = reactive({
      title: '变更明细',
      visible: false,
      content: {
        cols: [
          {
            field: 'bizType',
            label: '业务类型'
          },
          {
            field: 'bizDesc',
            label: '业务名称'
          },
          {
            field: 'fromRate',
            label: '原费率'
          },
          {
            field: 'toRate',
            label: '新费率'
          },
          {
            field: 'fromExt',
            label: '原额外手续费'
          },
          {
            field: 'toExt',
            label: '新额外手续费'
          },
          {
            field: 'status',
            label: '状态',
            options: mapper?.market.profitModifyDetailStatus
          }
        ]
      }
    });

    const showDetail = (data: DataItem) => {
      detailOpts.visible = true;
      detailRef.value.init(data.detailList);
    };

    tableOpts.commands.push({ label: '明细', action: showDetail });
    return { tableOpts, detailOpts, detailRef };
  }
});
